/* -------------------------------------------------------------------------

	PUNITED.top

	トップページのスクリプトを定義
	※jQuery必須
	Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.top) PUNITED.top = {};

(function (){
	// グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
	var ns = PUNITED.top;

	/* -----------------------------------------------------
	.page_main_image__description responsive
	----------------------------------------------------- */
	ns.top_main_description = function() {
		var $target = $('.top_main_image__description');
		//$target.closest('.top_main_image').next('.cta_btn').after($target.clone().addClass('liquid_container'));
		$('.top_main_image__copy').after($target.clone());
	}

	/* -----------------------------------------------------
	スマホ縦書き対応
	----------------------------------------------------- */
	ns.style_elem_writemode = function() {
		var $target = $('.elem_writemode');
		var timer = null;
		function setSize(){
			$target.each(function(){
				var tw = 0;
				$(this).children().each(function(){
					tw += $(this).outerWidth(true);
				});
				$(this).css({width: tw});
			});
		}
		$(window).on('resize', function(){
			clearTimeout(timer);
			timer = setTimeout(function() {
				if( PUNITED.common.ww > 640 ){
					setSize();
				} else {
					setSize();
				}
			}, 10);
		}).trigger('resize');
	}

	/* -----------------------------------------------------
	メインコピーの動き
	----------------------------------------------------- */
	ns.top_main_text = function() {
		$(document).scrollTop(0);
		var titlePos = $(".home_main_image__title").offset().top;

		$(window).on('scroll load', function(e){
			var video = $(".home_main_image__video");
			var targetItem = $(".home_main_image__title");
			var targetHeight = targetItem.height() / 2;

			var tracerPos = $(".home_main_image__title--tracer").offset().top - titlePos;

			var winPosition = $(window).scrollTop();

			if(winPosition > tracerPos) {
				targetItem.addClass('is_static');
			} else {
				targetItem.removeClass('is_static');
			}
		});
	}
})();

$(function(){
	PUNITED.top.top_main_description();
	PUNITED.top.top_main_text();
});
